<template>
  <fragment>
    <button class="list-group-item list-group-item-action" type="button" v-bind="collapseToggleAtts" :class="[hasExpiredMembership ? 'list-group-item-danger' : '', !isCollapsed ? 'active' : '']"  @click="toggle()">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h4 class="mb-1">{{ zone.name }}</h4>
          <p class="mb-1" v-if="hasMemberships">{{ membershipsCount }} active membership{{ membershipsCount > 1 ? 's' : '' }}</p>
          <p class="mb-1" v-if="!hasMemberships">No active memberships</p>
          <p class="mb-1" v-if="hasExpiredMembership">Expired</p>
        </div>

        <small v-if="!hasMemberships"><i class="fas fa-user-slash"></i></small>
        <small v-if="hasMemberships"><i :class="['fas', isCollapsed ? 'fa-plus' : 'fa-minus' ]"></i></small>
      </div>
    </button>

    <div class="list-group-item" :id="`${this.zone.id}`" v-bind="collapsePanelAtts" :class="['collapse', { 'show' : !isCollapsed}]">
      <dl v-for="(membership, index) in zone.membershipTypes" :key="'dashboard-zone-membership-' + index" :aria-label="`Membership ${index + 1} details`">
        <dt> <strong>Membership Type</strong> </dt>
        <dd>{{ membership.name }}</dd>
        <dt>Expiry Date</dt>
        <dd>{{ membership.expiryDate | datetime('MM/dd/yyyy') }}</dd>
      </dl>
    </div>
  </fragment>
</template>

<style scoped>
.membership {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem 0
}
.membership:last-child {
  padding-bottom: 1.25rem
}

</style>

<script>
import compareDesc from 'date-fns/compareDesc';

export default {
  name: "dashboard-member-zone",
  props: {
    parent: { type: String },
    zone: { type: Object, required: true }
  },
  data: function () {
    return {
      isCollapsed: true
    }
  },
  computed: {
    collapsePanelAtts () {
      return {
        'data-parent': `#${this.parent}`
      }
    },
    collapseToggleAtts () {
      let atts = {};
      (this.hasMemberships ? atts = {
        'data-toggle': 'collapse',
        'aria-expanded': 'false',
        'data-target': `#${this.zone.id}`,
        'aria-controls': `${this.zone.id}`
      } : atts = {
          disabled: 'disabled'
      });
      return atts;
    },
    hasMemberships () {
      return !!this.zone.membershipTypes.length;
    },
    membershipsCount() {
      return this.zone.membershipTypes.length;
    },
    hasExpiredMembership () {
      return !!this.zone.membershipTypes.find(membership => compareDesc(new Date(), new Date(membership.expiryDate)) === -1);
    }
  },
  methods: {
    toggle () {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script>
